import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

const PREFIX = 'Spec';

const classes = {
  icon: `${PREFIX}-icon`,
  title: `${PREFIX}-title`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.icon}`]: {
    marginBottom: theme.spacing(1.25),
    textAlign: 'center',
  },

  [`& .${classes.title}`]: {
    fontWeight: '400',
    textAlign: 'center',
    fontSize: theme.typography.pxToRem(14),
    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: '500',
    },
  },
}));

const Spec = (props) => {
  const { icon, title } = props;

  return (
    <StyledBox className={classes.spec}>
      <Box className={classes.icon}>{icon}</Box>
      <Typography variant="h5" className={classes.title}>
        {title}
      </Typography>
    </StyledBox>
  );
};

export default Spec;
