import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography, useTheme } from '@mui/material';

const PREFIX = 'HeaderAndDescription';

const classes = {
  root: `${PREFIX}-root`,
  upper: `${PREFIX}-upper`,
  icon: `${PREFIX}-icon`,
  title: `${PREFIX}-title`,
  price: `${PREFIX}-price`,
  desc: `${PREFIX}-desc`,
  marginTop: `${PREFIX}-marginTop`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },

  [`& .${classes.upper}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },

  [`& .${classes.icon}`]: {
    marginRight: theme.spacing(1),
  },

  [`& .${classes.title}`]: {
    fontWeight: '500',
    color: theme.palette.secondary.main,
  },

  [`& .${classes.price}`]: {
    color: theme.palette.secondary.light,
    whiteSpace: 'nowrap',
    marginLeft: theme.spacing(1),
    fontWeight: '500',
  },

  [`& .${classes.desc}`]: {
    color: theme.palette.grey['400'],
    marginTop: theme.spacing(0.25),
  },

  [`& .${classes.marginTop}`]: {
    marginTop: theme.spacing(0.5),
  },
}));

const HeaderAndDescription = (props) => {
  const {
    icon, iconColor, title, price, desc,
  } = props;

  const theme = useTheme();

  return (
    <Root className={classes.root}>
      {icon && (
        <Box
          className={classes.icon}
          color={iconColor || theme.palette.secondary.main}>
          {icon}
        </Box>
      )}

      <Box flexGrow="1">
        {title && (
          <Box className={classes.upper}>
            <Typography className={classes.title} varaint="h5">
              {title}
            </Typography>

            {price && (
              <Typography className={classes.price} variant="h5">
                {price}
              </Typography>
            )}
          </Box>
        )}

        {desc && (
          <>
            {typeof desc === 'string' ? (
              <Typography
                className={`${classes.desc} ${
                  title && desc ? classes.marginTop : ''
                }`}
                variant="body2"
                dangerouslySetInnerHTML={{
                  __html: desc,
                }}
              />
            ) : (
              <Typography
                className={`${classes.desc} ${
                  title && desc ? classes.marginTop : ''
                }`}
                variant="body2">
                {desc}
              </Typography>
            )}
          </>
        )}
      </Box>
    </Root>
  );
};

export default HeaderAndDescription;
