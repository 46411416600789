import React, { useEffect, useState } from 'react';
import TourItinerary from 'views/tours-module/TourView/components/TourItinerary';

const TourItineraryWrapper = (props) => {
  const {
    activitiesArr, tourName, deviceType, ssrRender, sameDayTour,
  } = props;

  // To fix a scrolling issue for slider
  const [isMoving, setIsMoving] = useState(false);
  let firstClientX;
  let firstClientY;
  let clientX;
  // eslint-disable-next-line no-unused-vars
  let clientY;
  const touchStart = (e) => {
    firstClientX = e.touches[0].clientX;
    firstClientY = e.touches[0].clientY;
  };
  const preventTouch = (e) => {
    const minValue = 5; // threshold
    clientX = e.touches[0].clientX - firstClientX;
    // eslint-disable-next-line no-unused-vars
    clientY = e.touches[0].clientY - firstClientY;
    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(clientX) > minValue) {
      e.preventDefault();
      e.returnValue = false;
      return false;
    }
    return true;
  };
  useEffect(() => {
    if (process.browser) {
      if (isMoving) {
        window.removeEventListener('touchstart', touchStart);
        window.removeEventListener('touchmove', preventTouch, {
          passive: false,
        });
      } else {
        window.addEventListener('touchstart', touchStart);
        window.addEventListener('touchmove', preventTouch, { passive: false });
      }
    }
  }, [isMoving]);
  return (
    <>
      <TourItinerary
        activitiesArr={activitiesArr}
        tourName={tourName}
        deviceType={deviceType}
        ssrRender={ssrRender}
        setIsMoving={setIsMoving}
        sameDayTour={sameDayTour}
      />
    </>
  );
};

export default TourItineraryWrapper;
