import React from 'react';
import { useTranslation } from 'next-i18next';

import ExpandableDescription from 'views/common/components/UI/ExpandableDescription';
import Typography500 from 'views/common/components/UI/Typography500/Typography500';

import Specs from 'views/tours-module/TourView/components/Specs';

const TourDescription = (props) => {
  const { tour } = props;

  const { t } = useTranslation('fe_er_motorcycle_tours');

  return (
    <>
      <Typography500 variant="h3">
        {t('fe_er_motorcycle_tours:overview')}
      </Typography500>

      <Specs
        distance={tour.distance}
        temperature={tour.temperature}
        seasonNames={tour.season_names}
      />

      <ExpandableDescription
        description={tour.description}
        collapsedSize={100}
        autoExpand
      />
    </>
  );
};

export default TourDescription;
