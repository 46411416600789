import React from 'react';
import GridsGallery from 'views/common/components/UI/GridsGallery';

const GalleryContainer = (props) => {
  const { gallery, ssrRender, deviceType } = props;

  const slidesArray = gallery
    && gallery.map((item) => {
      if (item.youtube_id) return item;
      return {
        urls: {
          large: item.image.large_url,
          small: item.image.thumbnail_url,
        },
      };
    });
  return (
    <>
      {slidesArray && slidesArray.length > 0 && (
        <GridsGallery
          shouldResetAutoplay={false}
          ssrRender={ssrRender}
          slidesArray={slidesArray}
          deviceType={deviceType}
          variant="tours"
          galleryHeight={{
            LG: '300px',
            MD: '490px',
            SM: '250px',
          }}
          sliderDots
        />
      )}
    </>
  );
};

export default GalleryContainer;
