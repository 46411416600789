import React from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'next-i18next';
import { Grid, Box } from '@mui/material';
import AcUnitIcon from '@mui/icons-material/AcUnit';

import Spec from 'views/common/components/UI/Spec';
import IconDistance from 'views/common/components/UI/Icons/IconDistance';
import IconTemperature from 'views/common/components/UI/Icons/IconTemperature';

const PREFIX = 'Specs';

const classes = {
  specsBox: `${PREFIX}-specsBox`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.specsBox}`]: {
    padding: theme.spacing(2, 0, 1.5),
    marginBottom: theme.spacing(1.5),
    borderBottom: `1px solid ${theme.palette.grey['50']}`,
  },
}));

const Specs = (props) => {
  const { distance, temperature, seasonNames } = props;

  const { t } = useTranslation('fe_er_motorcycle_tours');

  return (
    <Root>
      <Box className={classes.specsBox}>
        <Grid container spacing={2}>
          {distance && (
            <Grid item xs={4} lg={2}>
              <Spec
                icon={<IconDistance />}
                title={(
                  <>
                    {t('fe_er_motorcycle_tours:miles', {
                      miles: distance.miles,
                    })}
                    <br />
                    {t('fe_er_motorcycle_tours:km', {
                      km: distance.km,
                    })}
                  </>
                )}
              />
            </Grid>
          )}

          {seasonNames && (
            <Grid item xs={4} lg={2}>
              <Spec icon={<AcUnitIcon />} title={seasonNames.join(', ')} />
            </Grid>
          )}

          {temperature && (
            <Grid item xs={4} lg={2}>
              <Spec
                icon={<IconTemperature />}
                title={(
                  <>
                    {t('fe_er_motorcycle_tours:fahrenheit', {
                      low: temperature.fahrenheit.low,
                      high: temperature.fahrenheit.high,
                    })}
                    <br />
                    {t('fe_er_motorcycle_tours:celsius', {
                      low: temperature.celsius.low,
                      high: temperature.celsius.high,
                    })}
                  </>
                )}
              />
            </Grid>
          )}
        </Grid>
      </Box>
    </Root>
  );
};

export default Specs;
