import { isValidURL } from 'lib/utils/isValidURL';

export const getYouTubeID = (youtubeURL) => {
  let youtubeID;
  if (typeof youtubeURL === 'string') {
    // eslint-disable-next-line max-len
    const regex = /(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be\/?)(?:\/embed\/|\/shorts\?v=|\/shorts\/|\/watch\?v=|&v=)?([^\s&]+)/;
    if (isValidURL(youtubeURL)) {
      youtubeID = youtubeURL.match(regex);
      if (youtubeID?.length > 0) {
        return youtubeID[1];
      }
    }
  }

  return youtubeID;
};
