import React from 'react';
import { styled } from '@mui/material/styles';
import {
  Box,
  Typography,
  Grid,
  useTheme,
  useMediaQuery,
  Tooltip,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useTranslation } from 'next-i18next';

import moneyFormat from 'lib/utils/moneyFormat';
import Dinero from 'lib/utils/Dinero';

import Typography500 from 'views/common/components/UI/Typography500/Typography500';

const PREFIX = 'TourInclusions';

const classes = {
  inclusionTable: `${PREFIX}-inclusionTable`,
  inclusionSaving: `${PREFIX}-inclusionSaving`,
  triangle: `${PREFIX}-triangle`,
  inclusionSavingContainer: `${PREFIX}-inclusionSavingContainer`,
  inclusionTableHead: `${PREFIX}-inclusionTableHead`,
  inclusionTableHeadContainer: `${PREFIX}-inclusionTableHeadContainer`,
  inclusionRow: `${PREFIX}-inclusionRow`,
  inclusionRowContainer: `${PREFIX}-inclusionRowContainer`,
  infoIcon: `${PREFIX}-infoIcon`,
  tooltip: `${PREFIX}-tooltip`,
  totalPrice: `${PREFIX}-totalPrice`,
  totalPriceContainer: `${PREFIX}-totalPriceContainer`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.inclusionTable}`]: {
    border: `1px solid ${theme.palette.grey['50']}`,
  },

  [`& .${classes.inclusionSaving}`]: {
    marginBottom: theme.spacing(-0.125),
    '& .MuiGrid-item:nth-child(2)': {
      color: theme.palette.common.white,
      background: theme.palette.primary.main,
      position: 'relative',
      [theme.breakpoints.only('md')]: {
        borderRight: 'none',
      },
      [theme.breakpoints.up('md')]: {
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
      },
    },
    '& h4': {
      padding: theme.spacing(1),
      textAlign: 'center',
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(2),
      },
      '& p': {
        display: 'inline-block',
        marginLeft: theme.spacing(0.5),
      },
    },
  },

  [`& .${classes.triangle}`]: {
    width: '0',
    height: '0',
    borderWidth: '23px 23px 0 0',
    borderColor: `${theme.palette.primary.main} transparent transparent transparent`,
    borderStyle: 'solid',
    transform: 'rotate(16deg)',
    position: 'absolute',
    right: '3px',
    bottom: '-17px',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },

  [`& .${classes.inclusionSavingContainer}`]: {
    width: 'auto',
    marginBottom: theme.spacing(-0.25),
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(0, 2, 0),
    },
  },

  [`& .${classes.inclusionTableHead}`]: {
    borderBottom: `1px solid ${theme.palette.grey['50']}`,
    marginBottom: theme.spacing(-0.25),
    '& h5': {
      padding: theme.spacing(2, 1),
      textAlign: 'center',
      [theme.breakpoints.down('md')]: {
        fontSize: theme.typography.pxToRem(14),
      },
      '& h5': {
        color: theme.palette.secondary.light,
        padding: theme.spacing(0.5, 0, 0),
      },
      '& h6': {
        color: theme.palette.grey['300'],
        padding: theme.spacing(0.5, 0, 0),
        textDecoration: 'line-through',
        '& h5': {
          color: theme.palette.primary.main,
          display: 'inline-block',
          marginLeft: theme.spacing(0.5),
          padding: theme.spacing(0),
        },
      },
    },
    '& .MuiGrid-item:first-child h5': {
      textAlign: 'left',
    },
    '& .MuiGrid-item:nth-child(2)': {
      borderLeft: `1px solid ${theme.palette.grey['50']}`,
      borderRight: `1px solid ${theme.palette.grey['50']}`,
      [theme.breakpoints.up('md')]: {
        borderLeft: `1px solid ${theme.palette.primary.main}`,
        borderRight: `1px solid ${theme.palette.primary.main}`,
      },
    },
  },

  [`& .${classes.inclusionTableHeadContainer}`]: {
    width: 'auto',
    marginBottom: theme.spacing(-0.25),
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(0, 2, 0),
    },
  },

  [`& .${classes.inclusionRow}`]: {
    padding: theme.spacing(1, 0, 1.125),
    marginBottom: theme.spacing(-0.25),
    borderBottom: `1px solid ${theme.palette.grey['50']}`,
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(0, 2, 0),
      marginBottom: theme.spacing(-0.25),
    },
    '&:last-child': {
      borderBottom: 'none',
      '& .MuiGrid-item:nth-child(2)': {
        [theme.breakpoints.up('md')]: {
          borderBottom: `1px solid ${theme.palette.primary.main}`,
          borderBottomLeftRadius: 7,
          borderBottomRightRadius: 7,
        },
      },
    },
    '& .MuiGrid-item': {
      color: theme.palette.secondary.main,
      padding: theme.spacing(1),
      '&:nth-child(2)': {
        borderLeft: `1px solid ${theme.palette.grey['50']}`,
        borderRight: `1px solid ${theme.palette.grey['50']}`,
        [theme.breakpoints.up('md')]: {
          borderLeft: `1px solid ${theme.palette.primary.main}`,
          borderRight: `1px solid ${theme.palette.primary.main}`,
        },
        '& svg': {
          display: 'block',
          marginTop: theme.spacing(1),
        },
      },
    },
    '& h6': {
      padding: theme.spacing(0.5, 0),
    },
  },

  [`& .${classes.inclusionRowContainer}`]: {
    padding: theme.spacing(0, 1),
    margin: theme.spacing(-1),
  },

  [`& .${classes.infoIcon}`]: {
    verticalAlign: 'middle',
    marginLeft: theme.spacing(0.5),
    color: theme.palette.grey['300'],
  },

  [`& .${classes.tooltip}`]: {
    fontSize: theme.typography.pxToRem(13),
    padding: theme.spacing(1),
  },

  [`& .${classes.totalPrice}`]: {
    backgroundColor: theme.palette.grey['50'],
    padding: theme.spacing(1),
    marginBottom: theme.spacing(0.125),
  },

  [`& .${classes.totalPriceContainer}`]: {
    padding: theme.spacing(0.5, 1),
    '& .MuiGrid-item:last-child h6': {
      textAlign: 'right',
    },
  },
}));

const TourInclusions = (props) => {
  const { tourDetails, inclusions, includeBike } = props;

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('lg'));

  const { t } = useTranslation('fe_er_motorcycle_tours');

  const filterCondition = includeBike
    ? 'with_motorcycle'
    : 'without_motorcycle';

  const filterItems = (items) => {
    if (items) {
      return items.filter(
        (item) => item.visible_for === filterCondition || item.visible_for === 'both',
      );
    }
    return null;
  };

  const filteredIncludedItems = inclusions && filterItems(inclusions.included_items);
  const filteredNonIncludedItems = inclusions && filterItems(inclusions.non_included_items);

  return (
    <Root>
      {inclusions && (
        <>
          <Box align={smallScreen ? 'center' : 'left'} mb={smallScreen ? 3 : 5}>
            <Typography500 variant="h3">
              {`${tourDetails.days_count > 1 ? tourDetails.days_count : ''} ${
                tourDetails.days_count > 1
                  ? t('fe_er_motorcycle_tours:days')
                  : ''
              }
          ${tourDetails.tour_type.name} ${tourDetails.name} ${t(
                'fe_er_motorcycle_tours:inclusions',
              )}`}
            </Typography500>
          </Box>

          <Box className={classes.inclusionSaving}>
            <Grid
              container
              alignItems="flex-start"
              className={classes.inclusionSavingContainer}>
              <Grid item xs={1} md={6} />
              <Grid item xs={8} md={3} algin="center" className="save">
                <Typography500 variant="h4">
                  {`${t('fe_er_motorcycle_tours:save')} `}
                  {moneyFormat(
                    Dinero({
                      amount: includeBike
                        ? inclusions.total_savings.with_motorcycle.amount
                        : inclusions.total_savings.without_motorcycle.amount,
                      currency: includeBike
                        ? inclusions.total_savings.with_motorcycle.currency_code
                        : inclusions.total_savings.without_motorcycle
                          .currency_code,
                      precision: includeBike
                        ? inclusions.total_savings.with_motorcycle.precision
                        : inclusions.total_savings.without_motorcycle.precision,
                    }),
                  )}
                  <Typography variant="body2">
                    {t('fe_er_motorcycle_tours:per_person')}
                  </Typography>
                </Typography500>
                <div className={classes.triangle} />
              </Grid>
              <Grid item xs={3} />
            </Grid>
          </Box>
          <Box className={classes.inclusionTable}>
            <Box className={classes.inclusionTableHead}>
              <Grid
                container
                alignItems="stretch"
                className={classes.inclusionTableHeadContainer}>
                <Grid item xs={5} md={6} />
                <Grid item xs={4} md={3} algin="center">
                  <Typography500 variant="h5">
                    {smallScreen
                      ? t('fe_er_motorcycle_tours:tour_price')
                      : t('fe_er_motorcycle_tours:eagleRider_rour')}

                    <Typography variant="h6" component="div">
                      <Typography500
                        variant="h5"
                        color="primary"
                        component="span">
                        {' '}
                        {moneyFormat(
                          Dinero({
                            amount: includeBike
                              ? inclusions.tour_price.with_motorcycle.amount
                              : inclusions.tour_price.without_motorcycle.amount,
                            currency: includeBike
                              ? inclusions.tour_price.with_motorcycle
                                .currency_code
                              : inclusions.tour_price.without_motorcycle
                                .currency_code,
                            precision: includeBike
                              ? inclusions.tour_price.with_motorcycle.precision
                              : inclusions.tour_price.without_motorcycle
                                .precision,
                          }),
                        )}
                      </Typography500>
                    </Typography>
                  </Typography500>
                </Grid>
                <Grid item xs={3} algin="center">
                  <Typography500 variant="h5">
                    {t('fe_er_motorcycle_tours:individual_price')}
                    <Typography500 variant="h5" component="div">
                      {moneyFormat(
                        Dinero({
                          amount: includeBike
                            ? inclusions.included_items_total.with_motorcycle
                              .amount
                            : inclusions.included_items_total.without_motorcycle
                              .amount,
                          currency: includeBike
                            ? inclusions.included_items_total.with_motorcycle
                              .currency_code
                            : inclusions.included_items_total.without_motorcycle
                              .currency_code,
                          precision: includeBike
                            ? inclusions.included_items_total.with_motorcycle
                              .precision
                            : inclusions.included_items_total.without_motorcycle
                              .precision,
                        }),
                      )}
                    </Typography500>
                  </Typography500>
                </Grid>
              </Grid>
            </Box>

            {filteredIncludedItems?.map((item) => (
              <Box key={item.name} className={classes.inclusionRow}>
                <Grid
                  container
                  spacing={2}
                  alignItems="stretch"
                  className={classes.inclusionRowContainer}>
                  <Grid item xs={5} md={6}>
                    <Typography variant="h6">
                      {item.name}
                      {item.description && (
                        <Tooltip
                          title={item.description}
                          placement="top"
                          arrow
                          classes={{ tooltip: classes.tooltip }}>
                          <InfoIcon
                            fontSize="small"
                            className={classes.infoIcon}
                          />
                        </Tooltip>
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} md={3} align="center">
                    <CheckCircleIcon color="primary" fontSize="small" />
                  </Grid>
                  <Grid item xs={3} align="center">
                    <Typography variant="h6">
                      {item.price && item.price.amount != 0 ? (
                        <>
                          {moneyFormat(
                            Dinero({
                              amount: item.price.amount,
                              currency: item.price.currency_code,
                              precision: item.price.precision,
                            }),
                          )}
                          {item.price_per_person && (
                            <>{` ${t('fe_er_motorcycle_tours:per_person')}`}</>
                          )}
                        </>
                      ) : (
                        t('fe_er_motorcycle_tours:priceless')
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            ))}
          </Box>

          <Box className={classes.totalPrice}>
            <Grid
              container
              spacing={3}
              alignItems="stretch"
              className={classes.totalPriceContainer}>
              <Grid item xs={7}>
                <Typography500 variant="h6">
                  {t('fe_er_motorcycle_tours:total_tour_vlaue')}
                </Typography500>
              </Grid>
              <Grid item xs={5} algin="center">
                <Typography500 variant="h6">
                  {`${moneyFormat(
                    Dinero({
                      amount: includeBike
                        ? inclusions.included_items_total.with_motorcycle.amount
                        : inclusions.included_items_total.without_motorcycle
                          .amount,
                      currency: includeBike
                        ? inclusions.included_items_total.with_motorcycle
                          .currency_code
                        : inclusions.included_items_total.without_motorcycle
                          .currency_code,
                      precision: includeBike
                        ? inclusions.included_items_total.with_motorcycle
                          .precision
                        : inclusions.included_items_total.without_motorcycle
                          .precision,
                    }),
                  )} ${t('fe_er_motorcycle_tours:per_person_text')}`}
                </Typography500>
              </Grid>
            </Grid>
          </Box>

          <Box className={classes.totalPrice}>
            <Grid
              container
              spacing={3}
              alignItems="stretch"
              className={classes.totalPriceContainer}>
              <Grid item xs={7}>
                <Typography500 variant="h6">
                  {t('fe_er_motorcycle_tours:total_tour_price')}
                </Typography500>
              </Grid>
              <Grid item xs={5} algin="center">
                <Typography500 variant="h6">
                  {`${moneyFormat(
                    Dinero({
                      amount: includeBike
                        ? inclusions.tour_price.with_motorcycle.amount
                        : inclusions.tour_price.without_motorcycle.amount,
                      currency: includeBike
                        ? inclusions.tour_price.with_motorcycle.currency_code
                        : inclusions.tour_price.without_motorcycle
                          .currency_code,
                      precision: includeBike
                        ? inclusions.tour_price.with_motorcycle.precision
                        : inclusions.tour_price.without_motorcycle.precision,
                    }),
                  )} ${t('fe_er_motorcycle_tours:per_person_text')}`}
                </Typography500>
              </Grid>
            </Grid>
          </Box>

          <Box className={classes.totalPrice}>
            <Grid
              container
              spacing={3}
              alignItems="stretch"
              className={classes.totalPriceContainer}>
              <Grid item xs={7}>
                <Typography500 variant="h6">
                  {t('fe_er_motorcycle_tours:total_savings')}
                </Typography500>
              </Grid>
              <Grid item xs={5} algin="center">
                <Typography500 variant="h6">
                  {`${moneyFormat(
                    Dinero({
                      amount: includeBike
                        ? inclusions.total_savings.with_motorcycle.amount
                        : inclusions.total_savings.without_motorcycle.amount,
                      currency: includeBike
                        ? inclusions.total_savings.with_motorcycle.currency_code
                        : inclusions.total_savings.without_motorcycle
                          .currency_code,
                      precision: includeBike
                        ? inclusions.total_savings.with_motorcycle.precision
                        : inclusions.total_savings.without_motorcycle.precision,
                    }),
                  )} ${t('fe_er_motorcycle_tours:per_person_text')}`}
                </Typography500>
              </Grid>
            </Grid>
          </Box>

          {filteredNonIncludedItems.length > 0 && (
            <Box mt={4}>
              <Typography500 variant="h4">
                {t('fe_er_motorcycle_tours:not_included_title')}
              </Typography500>
              <Box className={classes.inclusionTable} mt={2}>
                {filteredNonIncludedItems?.map((item) => (
                  <Box key={item.name} className={classes.inclusionRow}>
                    <Grid
                      container
                      spacing={2}
                      alignItems="stretch"
                      className={classes.inclusionRowContainer}>
                      <Grid item xs={12}>
                        <Typography
                          key={item.name}
                          variant="h6"
                          component="div">
                          {item.name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                ))}
              </Box>
            </Box>
          )}
        </>
      )}
    </Root>
  );
};
export default TourInclusions;
