import React from 'react';
import { Typography, Grid, Box } from '@mui/material';
import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';

import Typography500 from 'views/common/components/UI/Typography500/Typography500';

const ShareList = dynamic(
  () => import('views/common/components/UI/ShareList'),
  {
    ssr: false,
  },
);
const TourMainTitleWrapper = (props) => {
  const { tour, showNumOfDaysAndNights = true } = props;

  const { t } = useTranslation('fe_er_motorcycle_tours');

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={10} lg={8}>
          <Typography500 variant="h2" component="h1">
            {tour.name}
          </Typography500>

          <Box my={1}>
            <Typography variant="h5">
              {tour.tour_type
                && tour.tour_type.name
                && `${tour.tour_type.name} ${t('fe_er_motorcycle_tours:tour')} ${
                  showNumOfDaysAndNights ? '-' : ''
                } `}

              {tour.days_count && showNumOfDaysAndNights && (
                <>
                  {`${tour.days_count} ${t(
                    'fe_er_motorcycle_tours:activity_day',
                  )}/ `}

                  {`${tour.days_count - 1} ${t(
                    'fe_er_motorcycle_tours:nights',
                  )}`}
                </>
              )}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={2} lg={4} align="right">
          <ShareList />
        </Grid>
      </Grid>
    </>
  );
};

export default TourMainTitleWrapper;
