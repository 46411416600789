import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import dynamic from 'next/dynamic';

import axios, { API } from 'lib/config/axios';
import { setAppData } from 'redux/features/globalData-slice';
import useErrorsHandler from 'views/common/hooks/useErrorsHandler';

import Reviews from 'views/tours-module/TourView/components/Reviews';
import { notifyAirbrake } from 'lib/config/airbrake';

const AuthOverlay = dynamic(
  () => import('views/common/components/Logical/Auth/AuthOverlay'),
  { ssr: false },
);

const ReviewsWrapper = (props) => {
  const {
    reviewsData, tourTitle, tourId, getTourData,
  } = props;

  const [reviewsDateState, setReviewsDataState] = useState(reviewsData);
  const [reviewError, setReviewError] = useState(null);
  const appState = useSelector((state) => state.globalData.appState);
  const dispatch = useDispatch();
  const handleErrorResponse = useErrorsHandler();
  const [hasReview, setHasReview] = useState(
    reviewsDateState.current_user_review_id !== null,
  );
  const [openAuthOverlay, setOpenAuthOverlay] = useState(false);

  // to update reviewsDataState when getTourData is called after sign in/sign up
  useEffect(() => {
    setReviewsDataState(reviewsData);
  }, [reviewsData]);

  const [reviewFormData, setReviewFormData] = useState(
    hasReview
      ? {
        title: reviewsDateState.reviews[0].title,
        body: reviewsDateState.reviews[0].body,
        star_rating: reviewsDateState.reviews[0].star_rating,
      }
      : { title: '', body: '', star_rating: '' },
  );

  const REVIEW_SUBMIT = 1;
  const VOTE_SUBMIT = 2;

  const [voteFormData, setVoteFormData] = useState();
  const [submitType, setSubmitType] = useState();

  const handleReviewFormChange = (e) => {
    setReviewFormData((prev) => ({
      ...prev,
      [e.target.name]:
        e.target.name === 'star_rating' ? +e.target.value : e.target.value,
    }));
  };

  // For Add Review
  const [openAddReviewOverlay, setOpenAddReviewOverlay] = useState(false);
  const handleAddReviewOverlayOpen = () => {
    setOpenAddReviewOverlay(true);
    setReviewFormData({ title: '', body: '', star_rating: '' });
  };
  const handleAddReviewOverlayClose = () => {
    setOpenAddReviewOverlay(false);
    setReviewError(null);
  };

  // For Edit Review
  const [openEditReviewOverlay, setOpenEditReviewOverlay] = useState(false);
  const handleEditReviewOverlayOpen = () => {
    setOpenEditReviewOverlay(true);
    setReviewFormData({
      title: reviewsDateState.reviews[0].title,
      body: reviewsDateState.reviews[0].body,
      star_rating: reviewsDateState.reviews[0].star_rating,
    });
  };
  const handleEditReviewOverlayClose = () => {
    setOpenEditReviewOverlay(false);
    setReviewError(null);
  };

  const handleAuthOverlaOpen = () => {
    setOpenAuthOverlay(true);
  };

  const handleAuthOverlayClose = () => {
    setOpenAuthOverlay(false);
  };

  const getUpdatedReviews = (newReviewId, currentUser) => {
    const newReivew = {
      id: newReviewId,
      reviewer: {
        avatar: {
          small_url: currentUser.avatar_url,
        },
        name: `${currentUser.first_name} ${currentUser.last_name}`,
      },
      created_at: dayjs().toISOString(),
      star_rating: reviewFormData.star_rating,
      title: reviewFormData.title,
      body: reviewFormData.body,
      votes: '0',
    };

    const updatedReviews = [newReivew];
    const reviewsLength = reviewsDateState.reviews.length;

    if (reviewsLength >= 1 && !hasReview) {
      updatedReviews.push({ ...reviewsDateState.reviews[0] });
    } else if (reviewsLength > 1 && hasReview) {
      updatedReviews[0].votes = reviewsDateState.reviews[0].votes;
      updatedReviews[0].current_user_vote = reviewsDateState.reviews[0].current_user_vote;
      updatedReviews.push({ ...reviewsDateState.reviews[1] });
    }
    return updatedReviews;
  };

  const submitReview = (authToken, currentUser) => {
    const updatedReviewData = {
      ...reviewFormData,
      star_rating: Number(reviewFormData.star_rating),
    };

    axios
      .post(API.tours.review.replace('{tour_id}', tourId), {
        authenticity_token: authToken,
        review: updatedReviewData,
      })
      .then((res) => {
        const result = res.data;

        let updatedTotalCount;
        if (hasReview) {
          updatedTotalCount = reviewsDateState.total_count;
          handleEditReviewOverlayClose();
        } else {
          updatedTotalCount = reviewsDateState.total_count + 1;
          handleAddReviewOverlayClose();
        }

        setReviewsDataState({
          ...reviewsDateState,
          current_user_review_id: result.review.id,
          reviews: getUpdatedReviews(result.review.id, currentUser),
          total_count: updatedTotalCount,
        });
        setHasReview(true);
        setReviewError(null);
      })
      .catch((error) => {
        switch (error.response.status) {
          case 406:
            setReviewError(error.response.data);
            break;
          default:
            handleErrorResponse(error, false);
        }
      });
  };

  const submitReviewCheck = () => {
    if (!appState.current_user) {
      handleAuthOverlaOpen();
      handleAddReviewOverlayClose();
      setSubmitType(REVIEW_SUBMIT);
    } else {
      submitReview(appState.authenticity_token, appState.current_user);
    }
  };

  const updateTargetReviewVote = (reviewId, newRank, vote) => {
    const clonedReviews = JSON.parse(JSON.stringify(reviewsDateState.reviews));

    const idx = clonedReviews.findIndex((review) => review.id === reviewId);
    clonedReviews[idx].votes = newRank;
    clonedReviews[idx].current_user_vote = vote;

    setReviewsDataState((prev) => ({
      ...prev,
      reviews: clonedReviews,
    }));
  };

  const submitVote = (reviewId, vote, authToken) => {
    axios
      .post(API.tours.reviewVote.replace('{id}', reviewId), {
        authenticity_token: authToken,
        like: vote,
      })
      .then((res) => {
        updateTargetReviewVote(reviewId, res.data.review.new_rank, vote);
      })
      .catch((error) => {
        handleErrorResponse(error, false);
      });
  };

  const submitVoteCheck = (reviewId, vote) => {
    if (!appState.current_user) {
      handleAuthOverlaOpen();
      setVoteFormData({ reviewId, vote });
      setSubmitType(VOTE_SUBMIT);
    } else {
      submitVote(reviewId, vote, appState.authenticity_token);
    }
  };

  const handleSignCallback = async () => {
    try {
      const { data: newAppState } = await axios.get(API.layout);
      dispatch(
        setAppData({
          ...newAppState,
        }),
      );
      if (submitType === REVIEW_SUBMIT) {
        submitReview(newAppState.authenticity_token, newAppState.current_user);
      } else {
        submitVote(
          voteFormData.reviewId,
          voteFormData.vote,
          newAppState.authenticity_token,
        );
      }
      getTourData();
    } catch (error) {
      notifyAirbrake(error);
    }
  };
  const signInRedirectCallback = () => {
    handleSignCallback();
  };

  const signUpRedirectCallback = () => {
    handleSignCallback();
  };

  return (
    <>
      <Reviews
        totalReviews={reviewsDateState.reviews}
        totalCount={reviewsDateState.total_count}
        tourTitle={tourTitle}
        onSubmitReview={submitReviewCheck}
        reviewError={reviewError}
        hasReview={hasReview}
        onSubmitVote={submitVoteCheck}
        addReviewState={{
          open: openAddReviewOverlay,
          handleOpen: handleAddReviewOverlayOpen,
          handleClose: handleAddReviewOverlayClose,
        }}
        editReviewState={{
          open: openEditReviewOverlay,
          handleOpen: handleEditReviewOverlayOpen,
          handleClose: handleEditReviewOverlayClose,
        }}
        reviewFormData={reviewFormData}
        onDataChange={handleReviewFormChange}
      />
      {openAuthOverlay && (
        <AuthOverlay
          openAuthOverlay={openAuthOverlay}
          handleAuthOverlaOpen={handleAuthOverlaOpen}
          handleAuthOverlayClose={handleAuthOverlayClose}
          signInRedirectCallback={signInRedirectCallback}
          signUpRedirectCallback={signUpRedirectCallback}
        />
      )}
    </>
  );
};

export default ReviewsWrapper;
