import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const IconDistance = (props) => (
  <SvgIcon
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 21"
    fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.41028 14.5311H12.5904V13.536H7.41028V14.5311ZM17.1595 12.7875L16.007 12.3297L16.1427 12.0042L17.2899 12.4611C17.5847 11.7052 17.7536 10.908 17.7901 10.0999H16.5592V9.39756H17.7901C17.7523 8.55855 17.5721 7.73152 17.257 6.95029L16.1128 7.42076L15.9741 7.09737L17.1178 6.6269C16.3184 4.89367 14.8895 3.5124 13.1079 2.75086L12.6394 3.87701L12.3078 3.74562L12.7755 2.62371C12.0016 2.33547 11.1856 2.1702 10.3583 2.13417V3.33788H9.64149V2.1346C8.78295 2.17135 7.93664 2.34753 7.13723 2.65592L7.61918 3.77317L7.28806 3.90965L6.80697 2.79155C6.05288 3.1248 5.35758 3.57286 4.74696 4.11903L5.61552 4.96798L5.10843 5.46388L4.23987 4.61492C3.65859 5.23371 3.18561 5.94198 2.83995 6.71125L3.99239 7.16857L3.85717 7.49324L2.70993 7.03676C2.41493 7.7924 2.24605 8.5895 2.20977 9.39756H3.44066V10.099H2.20934C2.2466 10.938 2.42678 11.7649 2.74243 12.5459L3.88577 12.0754L4.02447 12.3992L2.88156 12.8697C3.22206 13.6069 3.68026 14.2864 4.239 14.8829L5.108 14.034L5.61509 14.5299L4.74653 15.3776C6.13518 16.6169 7.97632 17.3806 10.0004 17.3806C12.0235 17.3806 13.8634 16.6198 15.2533 15.378L14.3843 14.5299L14.8914 14.034L15.7595 14.8825C16.3412 14.2645 16.8143 13.5566 17.1595 12.7875ZM19.1666 9.74893C19.1666 14.688 15.0544 18.7077 10.0004 18.7077C4.94633 18.7077 0.833252 14.688 0.833252 9.74893C0.833252 4.80947 4.94633 0.791016 10.0004 0.791016C15.0539 0.791016 19.1666 4.80989 19.1666 9.74935V9.74893ZM10.7246 8.577L14.9733 4.88237L11.2204 9.0801C11.3404 9.28386 11.4042 9.51464 11.4055 9.74977C11.4052 10.1141 11.257 10.4635 10.9935 10.721C10.7299 10.9785 10.3725 11.1231 9.99992 11.123C9.62726 11.123 9.26986 10.9783 9.00635 10.7206C8.74284 10.4629 8.5948 10.1134 8.5948 9.74893C8.5948 9.38449 8.74284 9.03498 9.00635 8.77729C9.26986 8.5196 9.62726 8.37483 9.99992 8.37483C10.2669 8.37483 10.5122 8.45112 10.7246 8.577Z"
      fill="black"
    />
  </SvgIcon>
);

export default IconDistance;
