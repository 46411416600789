import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, ButtonBase } from '@mui/material';
import Image from 'next/image';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import useAppNavigator from 'views/common/hooks/useAppNavigator';
import PATHS from 'lib/navigation/paths';
import Typography500 from 'views/common/components/UI/Typography500/Typography500';
import blur from 'public/imgs/1x1.webp';

const PREFIX = 'ExperienceTouts';

const classes = {
  experienceTout: `${PREFIX}-experienceTout`,
  experienceToutImgBox: `${PREFIX}-experienceToutImgBox`,
  experienceToutTitle: `${PREFIX}-experienceToutTitle`,
  experienceToutLink: `${PREFIX}-experienceToutLink`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.experienceTout}`]: {
    borderRadius: 7,
    overflow: 'hidden',
    position: 'relative',
  },

  [`& .${classes.experienceToutImgBox}`]: {
    position: 'relative',
    height: theme.spacing(25),
  },

  [`& .${classes.experienceToutTitle}`]: {
    padding: theme.spacing(1.5),
    border: `1px solid ${theme.palette.grey['100']}`,
    borderTop: 'none',
    borderBottomLeftRadius: 7,
    borderBottomRightRadius: 7,
    '& svg': {
      verticalAlign: 'middle',
      marginLeft: theme.spacing(0.5),
    },
  },

  [`& .${classes.experienceToutLink}`]: {
    display: 'block',
    width: '100%',
    textAlign: 'left',
    padding: theme.spacing(2, 0),
    [theme.breakpoints.down('xl')]: {
      order: 2,
    },
  },
}));

const ExperienceTouts = (props) => {
  const { experienceTouts } = props;

  const appNavigator = useAppNavigator();

  return (
    <Root>
      {experienceTouts && (
        <ButtonBase
          className={classes.experienceToutLink}
          onClick={() => appNavigator.push(PATHS.experience(experienceTouts.slug))}>
          <Box className={classes.experienceTout}>
            <Box className={classes.experienceToutImgBox}>
              <Image
                className={classes.experienceToutImg}
                src={experienceTouts.image.small_url}
                alt={experienceTouts.identifier}
                placeholder="blur"
                blurDataURL={blur.src}
                layout="fill"
                objectFit="cover"
                unoptimized
              />
            </Box>
            <Box className={classes.experienceToutTitle}>
              <Typography500 variant="h6" color="primary">
                {experienceTouts.title}
                <ArrowForwardIcon />
              </Typography500>
            </Box>
          </Box>
        </ButtonBase>
      )}
    </Root>
  );
};

export default ExperienceTouts;
