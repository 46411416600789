import React from 'react';
import { styled } from '@mui/material/styles';
import {
  Box, Typography, Grid, Button, ButtonBase,
} from '@mui/material';
import RoomIcon from '@mui/icons-material/Room';
import EventNoteIcon from '@mui/icons-material/EventNote';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';

import moneyFormat from 'lib/utils/moneyFormat';
import Dinero from 'lib/utils/Dinero';
import useAppNavigator from 'views/common/hooks/useAppNavigator';
import PATHS from 'lib/navigation/paths';
import { TOUR_TYPES } from 'lib/utils/CONSTANTS';

import Typography500 from 'views/common/components/UI/Typography500/Typography500';
import blur from 'public/imgs/1x1.webp';

const PREFIX = 'TourCard';

const classes = {
  tourCard: `${PREFIX}-tourCard`,
  tourImgBox: `${PREFIX}-tourImgBox`,
  tourImg: `${PREFIX}-tourImg`,
  tourDetails: `${PREFIX}-tourDetails`,
  tourInfo: `${PREFIX}-tourInfo`,
  tourLocationDays: `${PREFIX}-tourLocationDays`,
  ligthFontWeight: `${PREFIX}-ligthFontWeight`,
  cardBtn: `${PREFIX}-cardBtn`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const StyledButtonBase = styled(ButtonBase)(({ theme }) => ({
  [`& .${classes.tourCard}`]: {
    position: 'relative',
    borderRadius: 4,
    boxShadow: '0px 0px 11px rgba(0, 0, 0, 0.09)',
    overflow: 'hidden',
    margin: theme.spacing(0, 0.5),
    height: '100%',
  },

  [`& .${classes.tourImgBox}`]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    height: theme.spacing(25),
  },

  [`& .${classes.tourImg}`]: {
    height: '100%',
    minWidth: '100%',
    width: 'auto',
  },

  [`& .${classes.tourDetails}`]: {
    padding: theme.spacing(2),
    height: 'calc(100% - 200px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '& p': {
      color: theme.palette.grey['300'],
    },
  },

  [`& .${classes.tourInfo}`]: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(2),
    color: theme.palette.secondary.light,
  },

  [`& .${classes.tourLocationDays}`]: {
    '& svg': {
      verticalAlign: 'middle',
      width: theme.spacing(2),
      height: theme.spacing(2),
      marginRight: theme.spacing(1),
    },
    '& p:first-child': {
      marginBottom: theme.spacing(1),
    },
  },

  [`& .${classes.ligthFontWeight}`]: {
    fontWeight: '300',
  },

  [`&.${classes.cardBtn}`]: {
    display: 'block',
    width: '100%',
    height: '100%',
    textAlign: 'left',
    padding: theme.spacing(2, 0),
  },
}));

const TourCard = (props) => {
  const { tour } = props;

  const appNavigator = useAppNavigator();
  const { t } = useTranslation('fe_er_motorcycle_tours');
  const getMinStartingPrice = () => {
    if (tour.starting_price.with_motorcycle) {
      let minPrice = { ...tour.starting_price.with_motorcycle };

      if (
        tour.starting_price.without_motorcycle
        && tour.starting_price.without_motorcycle.amount
      ) {
        if (tour.starting_price.without_motorcycle.amount < minPrice.amount) {
          minPrice = { ...tour.starting_price.without_motorcycle };
        }
      }

      return moneyFormat(
        Dinero({
          amount: minPrice.amount,
          currency: minPrice.currency_code,
          precision: minPrice.precision,
        }),
      );
    }
    if (tour.starting_price.without_motorcycle) {
      return moneyFormat(
        Dinero({
          amount: tour.starting_price.without_motorcycle.amount,
          currency: tour.starting_price.without_motorcycle.currency_code,
          precision: tour.starting_price.without_motorcycle.precision,
        }),
      );
    }
    return null;
  };

  return (
    <StyledButtonBase
      className={classes.cardBtn}
      onClick={() => appNavigator.push(PATHS.tour.index(tour.tour_type.slug, tour.slug))}>
      <Box className={classes.tourCard}>
        <Box className={classes.tourImgBox}>
          <Image
            className={classes.tourImg}
            src={tour.map?.large_url || ' '}
            alt={tour.name}
            placeholder="blur"
            blurDataURL={blur.src}
            layout="fill"
            objectFit="cover"
            unoptimized
          />
        </Box>

        <Box className={classes.tourDetails}>
          <div>
            <Typography500 variant="h5">{tour.name}</Typography500>
            <Typography variant="body2">{tour.tour_type.name}</Typography>
          </div>
          <div>
            <Grid
              container
              alignItems="flex-start"
              spacing={1}
              className={classes.tourInfo}>
              <Grid item xs={7} className={classes.tourLocationDays}>
                <Typography variant="caption" component="p">
                  <RoomIcon />
                  {tour.start_location_name}
                </Typography>
                {tour.days_count
                  && tour.tour_type.identifier !== TOUR_TYPES.GUIDED_OFFROAD && (
                    <Typography variant="caption" component="p">
                      <EventNoteIcon />
                      {`${tour.days_count} ${t(
                        'fe_er_motorcycle_tours:activity_day',
                      )}/ `}
                      {`${tour.days_count - 1} ${t(
                        'fe_er_motorcycle_tours:nights',
                      )}`}
                    </Typography>
                )}
              </Grid>
              <Grid item xs={5} align="right">
                {(tour.starting_price.with_motorcycle
                  || tour.starting_price.without_motorcycl) && (
                  <>
                    <Typography
                      variant="caption"
                      display="block"
                      className={classes.ligthFontWeight}>
                      {t('fe_er_motorcycle_tours:starting_at')}
                    </Typography>
                    <Typography500 variant="h4" color="primary">
                      {getMinStartingPrice()}
                    </Typography500>
                  </>
                )}
              </Grid>
            </Grid>

            <Button
              color="primary"
              disableElevation
              variant="contained"
              fullWidth
              component="span"
              onClick={() => appNavigator.push(
                PATHS.tour.index(tour.tour_type.slug, tour.slug),
              )}>
              {t('fe_er_motorcycle_tours:view_tour')}
            </Button>
          </div>
        </Box>
      </Box>
    </StyledButtonBase>
  );
};

export default TourCard;
